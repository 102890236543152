import { useContext, useState } from "react";
import styles from "./user-info-card.module.scss";
import avatar from "assets/images/default.svg";
import callIco from "assets/images/call.svg";
import locationIco from "assets/images/location.svg";
import dateIco from "assets/images/date-ico.svg";
import deleteIcon from "assets/images/delete.svg";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import { GlobalContext, actions } from "context";
import AuthServices from "api/services/auth-services";
import { ConfirmDialog, Snackbar } from "components";
import EditAdmin from "app/admin/edit-admin/edit-admin";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import moment from "moment-timezone";
import { getErrorMessage } from "utils/validator";

function formatDate(dateJoining) {
  try {
    const date = moment(dateJoining);
    if (!date.isValid()) {
      console.log("Invalid date format");
      return null;
    }
    return date.format("MM/DD/YYYY (z)");
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return null;
  }
}

const UserInfoCard = ({ item, admin, getData }) => {
  const {
    name,
    id,
    phone,
    date_joining,
    doj,
    address,
    profile,
    status,
    account_id,
    qa_user,
    state,
  } = item;

  const {
    dispatch,
    state: { isAdmin },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const history = useHistory(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const [openDeleteUserPrompt, setOpenDeleteUserPrompt] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const userInfo = () => {
    storeHandler(actions.USER_ID, { id, admin });
    if (admin) {
      setOpenEditAdmin(true);
    } else {
      history.push("/user-info");
    }
  };

  const openPopup = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const openDeletePopup = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpenDeleteUserPrompt((prevState) => !prevState);
  };

  const onConfirm = () => {
    toggleActive(!status);
    setOpenDialog(false);
  };

  const toggleActive = async (enable) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      let response = {};
      if (admin) {
        response = enable
          ? await AuthServices.activateUser(id)
          : await AuthServices.deActivateUser(id);
      } else {
        response = enable
          ? await AuthServices.activateFreelancer(id)
          : await AuthServices.deactivateFreelancer(id);
      }
      storeHandler(actions.SHOW_LOADER, false);
      const { message } = response;
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "success",
      };
      setSnackBarContent({ ...content });
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: MESSAGE,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const deleteUser = async () => {
    openDeletePopup();
    storeHandler(actions.SHOW_LOADER, true);
    try {
      let response = {};
      if (admin) {
        response = await AuthServices.deleteUser(id);
      } else {
        response = await AuthServices.deleteFreelancer(id);
      }
      storeHandler(actions.SHOW_LOADER, false);
      const { message } = response;
      setShowSnackBar(true);
      const content = {
        message: message,
        class: "delete",
      };
      setSnackBarContent({ ...content });
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      const MESSAGE = getErrorMessage(error);
      setShowSnackBar(true);
      const content = {
        message: MESSAGE,
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const closeEditAdmin = () => {
    setOpenEditAdmin(false);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div style={{ position: "relative" }}>
      {/* {!status && <div className={styles.overly}></div>} */}
      <div
        className={`${styles.freeLancerCard} p-3 d-flex mb-4`}
        onClick={userInfo}
      >
        <div className={`${styles.avatar}`}>
          <img src={profile || avatar} alt={"Profile"} />
        </div>
        <div className="pl-3 flex-grow-1">
          <p className="txt-bold txt-md m-0 d-flex justify-content-between align-items-start">
            <div className="flex-grow-1">
              <div className={`${styles.infoName}`}>
                <span>{name}</span>
              </div>
              <div
                style={{ color: "var(--error-main)" }}
                className="txt-xs txt-medium m-0 pt-1"
              >
                {account_id}
              </div>
            </div>
            {IS_ADMIN && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={styles.switch}
              >
                <Switch
                  size="small"
                  name="checkedA"
                  color="primary"
                  checked={status}
                  onClick={openPopup}
                  className={styles.toggleActive}
                />
                {admin && (
                  <img
                    style={{ position: "relative", top: ".3rem" }}
                    className={`${styles.iconDelete} mr-1`}
                    src={deleteIcon}
                    alt="call"
                    onClick={openDeletePopup}
                  />
                )}
              </Stack>
            )}
          </p>
          <Stack gap={".3rem"}>
            <p
              className={`txt-xs txt-medium txt-white mb-1 mt-2 d-flex align-items-center`}
            >
              <img className={`${styles.icon} mr-2`} src={callIco} alt="call" />
              <span className={`${styles.info}`}>{phone}</span>
            </p>
            <p className="txt-xs txt-medium txt-white  mb-1 mt-0 d-flex align-items-center">
              <img src={dateIco} alt="call" className={`${styles.icon} mr-2`} />{" "}
              <span className={`${styles.info}`}>
                {date_joining || doj ? formatDate(date_joining || doj) : ""}
              </span>{" "}
            </p>
            <p className="txt-xs txt-medium txt-white  mb-1 mt-0 d-flex align-items-center">
              <img
                src={locationIco}
                alt="location"
                className={`${styles.icon} mr-2`}
              />{" "}
              <Stack
                direction="row"
                justifyContent="space-between"
                width={"100%"}
              >
                <span className={`${styles.info}`}>{state}</span>
                {admin && (
                  <span
                    style={{
                      position: "relative",
                      top: ".7rem",
                      pointerEvents: "none",
                    }}
                    className={`${styles.info}`}
                  >
                    {!qa_user ? "Admin" : "QA"}
                  </span>
                )}
              </Stack>
            </p>
          </Stack>
        </div>
      </div>
      {openEditAdmin && (
        <EditAdmin
          info={item}
          handleClose={closeEditAdmin}
          open={openEditAdmin}
          getData={getData}
        />
      )}

      <ConfirmDialog
        open={openDeleteUserPrompt}
        onClose={setOpenDeleteUserPrompt}
        onConfirm={deleteUser}
        message={"Are you Sure you want to Delete?"}
      />

      <ConfirmDialog
        isPrompt={false}
        open={openDialog}
        onClose={setOpenDialog}
        onConfirm={onConfirm}
        message={`Are you Sure you want to ${
          status ? "disable" : "enable"
        } this ${admin ? (!qa_user ? "admin" : "QA") : "Contractor"}?`}
      />

      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={(val) => {
          setShowSnackBar(val);
          if (snackbarContent?.class !== "error") {
            if (typeof getData === "function") {
              getData();
            }
          }
        }}
      />
    </div>
  );
};

export default UserInfoCard;
